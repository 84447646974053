




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'

@Component({
  components: {
    ExpandIcon,
  },
})
export default class FilterGroup extends Vue {
  @Prop({ type: String, default: '' }) private label!: string
  @Prop({ type: String, default: 'Atur Filter' }) private placeholder!: string
  @Prop({ type: Boolean, default: false }) private disabled!: boolean
  @Prop({ type: Number, default: 0 }) private counter!: boolean
  @Prop({ type: Number, default: 350 }) private maxHeight!: number

  isOpen = false
  optimizedHeight = this.maxHeight
  preferredOpenDirection = 'below'

  mounted(): void {
    window.addEventListener('click', () => {
      this.isOpen = false
    })
  }

  get isElOpen(): boolean {
    return this.isOpen ? this.$el.contains(document.activeElement) : false
  }

  public activate(e: Event): void {
    e.stopPropagation()
    if (this.isOpen || this.disabled) return

    this.adjustPosition()

    this.isOpen = true
  }

  public deactivate(): void {
    if (!this.isOpen) return

    this.isOpen = false
  }

  public toggle(e: Event): void {
    this.isOpen ? this.deactivate() : this.activate(e)
  }

  public reset(): void {
    this.$emit('reset')
  }

  private adjustPosition(): void {
    if (typeof window === 'undefined') return

    const spaceAbove = this.$el.getBoundingClientRect().top
    const spaceBelow =
      window.innerHeight - this.$el.getBoundingClientRect().bottom
    const hasEnoughSpaceBelow = spaceBelow > this.maxHeight

    if (hasEnoughSpaceBelow || spaceBelow > spaceAbove) {
      this.preferredOpenDirection = 'below'
      this.optimizedHeight = Math.min(spaceBelow - 40, this.maxHeight)
    } else {
      this.preferredOpenDirection = 'above'
      this.optimizedHeight = Math.min(spaceAbove - 40, this.maxHeight)
    }
  }

  public isAbove(): boolean {
    return this.preferredOpenDirection === 'above'
  }
}
