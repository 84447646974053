


















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CheckBox from '@/app/ui/components/CheckBox/index.vue'

interface IOptions {
  label: string
  value: string | number
}

@Component({
  name: 'DropdownCheckbox',
  components: {
    CaretDownIcon,
    CloseLine,
    CheckedIcon,
    ExpandIcon,
    CheckBox,
  },
})
export default class DropdownCheckbox extends Vue {
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: Array, default: () => [] }) value!: string[]
  @Prop({ type: String, default: 'Search' }) searchPlaceholder!: string
  @Prop({ type: Array, default: () => [] }) options!: IOptions[]
  @Prop({ type: Boolean, default: false }) withSearch!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Number, default: 300 }) private maxHeight!: number

  isOpen = false
  keyword = ''
  tempValue: Array<string> = []
  optimizedHeight = this.maxHeight
  preferredOpenDirection = 'below'

  created(): void {
    this.tempValue = this.value
  }

  get filteredOptions(): Array<IOptions> {
    return this.onFilterOptions(this.options, this.keyword)
  }

  get isSelectedAll(): boolean {
    return (
      this.options.filter(option => !this.value.includes(option.label))
        .length === 0
    )
  }

  private onSelectOption(value: string): void {
    if (this.isSelectedAll) return
    if (this.tempValue.includes(value)) {
      this.tempValue = this.tempValue.filter(item => item !== value)
    } else {
      this.tempValue.push(value)
    }
    this.$emit('input', this.tempValue)
  }

  private onSelectAll(): void {
    if (this.isSelectedAll) {
      this.$emit('input', [])
    } else {
      this.$emit(
        'input',
        this.options.map(option => option.label)
      )
    }
  }

  private onRemoveItem(value: string): void {
    this.tempValue = this.tempValue.filter(item => item !== value)
    this.$emit('input', this.tempValue)
  }

  private isChecked(value: string): boolean {
    return this.tempValue.includes(value)
  }

  private onFilterOptions(
    list: Array<IOptions>,
    keyword: string
  ): Array<IOptions> {
    return list.filter(item =>
      (<string>item.value).toLowerCase().startsWith(keyword.toLowerCase())
    )
  }

  private activate(): void {
    if (this.isOpen || this.disabled) return

    this.adjustPosition()

    this.isOpen = true
    ;(<HTMLElement>this.$el).focus()
  }

  private deactivate(): void {
    if (!this.isOpen) return

    this.keyword = ''
    this.isOpen = false
  }

  public toggle(): void {
    this.isOpen ? this.deactivate() : this.activate()
  }

  private adjustPosition(): void {
    if (typeof window === 'undefined') return

    const spaceAbove = this.$el.getBoundingClientRect().top
    const spaceBelow =
      window.innerHeight - this.$el.getBoundingClientRect().bottom
    const hasEnoughSpaceBelow = spaceBelow > this.maxHeight

    if (hasEnoughSpaceBelow || spaceBelow > spaceAbove) {
      this.preferredOpenDirection = 'below'
      this.optimizedHeight = Math.min(spaceBelow - 40, this.maxHeight)
    } else {
      this.preferredOpenDirection = 'above'
      this.optimizedHeight = Math.min(spaceAbove - 40, this.maxHeight)
    }
  }

  public isAbove(): boolean {
    return this.preferredOpenDirection === 'above'
  }

  @Watch('value')
  private onChangeValue(value: string[]): void {
    this.tempValue = value
  }
}
