



































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import IconSync from '@/app/ui/assets/icon_sync.vue'
import CODSearchInput from '../../COD/components/CODSearchInput/index.vue'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import {
  DataObject,
  HeaderObject,
  SortValueObject,
} from '@/app/ui/components/DataTableV2/type'
import FilterGroup from '@/app/ui/components/FilterGroup/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DropdownCheckbox from '@/app/ui/components/DropdownCheckbox/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PickupController from '@/app/ui/controllers/PickupController'
import { Utils } from '@/app/infrastructures/misc'
import {
  PickupCorporate,
  PickupCorporateSummary,
} from '@/domain/entities/Pickup'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'

dayjs.extend(dayjsDuration)

@Component({
  components: {
    Button,
    IconSync,
    CODSearchInput,
    FilterGroup,
    DropdownSelect,
    DropdownCheckbox,
    DataTableV2,
    PaginationNav,
  },
})
export default class PickupCorporateList extends Vue {
  utils = Utils
  controller = PickupController
  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Order ID', '160px'),
    this.headerCellMapper('Nama customer', '200px'),
    this.headerCellMapper('No. handphone', '190px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Tanggal CRRNFD', '176px', 'date_time', '20px'),
    this.headerCellMapper('Durasi CRRNFD', '160px', 'duration', '20px'),
    this.headerCellMapper('NFD status', '140px'),
    this.headerCellMapper('Atur', '166px'),
  ]

  searchOptions = [
    {
      label: 'Shipment ID',
      value: 'order_id',
    },
    {
      label: 'Nama customer',
      value: 'customer_name',
    },
    {
      label: 'No. handphone',
      value: 'phone_number',
    },
    {
      label: 'Nama klien',
      value: 'client_name',
    },
    {
      label: 'Klien ID',
      value: 'client_id',
    },
    {
      label: 'Kurir terakhir',
      value: 'latest_courier',
    },
  ]

  productTypeOptions = [
    {
      label: 'Sameday',
      value: 'sameday',
    },
    {
      label: 'Bosspack',
      value: 'bosspack',
    },
    {
      label: 'Onepack',
      value: 'onepack',
    },
    {
      label: 'Regpack',
      value: 'regpack',
    },
    {
      label: 'Jagopack',
      value: 'jagopack',
    },
    {
      label: 'Bigpack',
      value: 'bigpack',
    },
    {
      label: 'Jumbopack',
      value: 'jumbopack',
    },
    {
      label: 'Otopack 150',
      value: 'otopack150',
    },
    {
      label: 'Otopack 250',
      value: 'otopack250',
    },
  ]

  cancelReasonOptions = [
    {
      label: '-',
      value: '-',
    },
    {
      label: 'Customer Ingin Mengubah Jadwal Pickup/Pengantaran',
      value: 'PAGLH',
    },
    {
      label: 'Customer Sulit Dihubungi',
      value: 'CSTSD',
    },
    {
      label: 'Customer Tidak Berada di Lokasi',
      value: 'PNTDA',
    },
    {
      label: 'Sudah Melewati Batas Waktu Pickup',
      value: 'SMBWP',
    },
    {
      label: 'Toko Tutup',
      value: 'TKTP',
    },
    {
      label: 'Produk Pesanan Tidak Tersedia/habis',
      value: 'PPTT',
    },
    {
      label: 'Paket Belum Siap di Pickup',
      value: 'BBSDP',
    },
    {
      label: 'Customer Ingin Mengubah Ekspedisi',
      value: 'CSTME',
    },
    {
      label: 'Lokasi Pickup Terlalu Jauh',
      value: 'APTLJ',
    },
    {
      label: 'Kendala Motor Atau Ponsel',
      value: 'KNRSK',
    },
    {
      label: 'Customer Dropoff ke POS/HUB Terdekat',
      value: 'DROP',
    },
    {
      label: 'Paket Diambil Kurir Lain',
      value: 'PKDKL',
    },
    {
      label: 'Alamat Tidak Ketemu',
      value: 'ALMSL',
    },
    {
      label: 'Lokasi Tutup Atau Pindah ',
      value: 'TLPST',
    },
    {
      label: 'Force Majeur',
      value: 'KLTDM',
    },
    {
      label: 'Dibatalkan oleh Admin',
      value: 'ADMIN',
    },
    {
      label: 'Lokasi Tutup',
      value: 'LKTTP',
    },
    {
      label: 'Lokasi Pindah',
      value: 'LKPDH',
    },
    {
      label: 'Kendala lainnya',
      value: 'ZZZZZ',
    },
  ]

  NFDStatusOptions = [
    {
      label: '1 NFD',
      value: '1',
    },
    {
      label: '2 NFD',
      value: '2',
    },
    {
      label: '3 NFD',
      value: '3',
    },
    {
      label: '4 NFD',
      value: '4',
    },
    {
      label: '5 NFD',
      value: '5',
    },
    {
      label: '>5 NFD',
      value: '-1',
    },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
    searchBy: this.searchOptions[0],
    productType: [],
    cancelReasonType: [],
    totalNfdStatus: <DropDownLabelValue<string | number> | null>null,
    sort: <SortValueObject>{
      name: '',
      direction: '',
    },
  }

  tableData: DataObject[][] = []

  mounted(): void {
    this.fetchPickup(true)
  }

  get params(): Record<string, string | number> {
    return {
      statusId: 'CRRNFD',
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      dateFrom: Utils.formatDateWithIDLocale(
        new Date(new Date().setDate(new Date().getDate() - 31)).toISOString(),
        'YYYY-MM-DD'
      ),
      keyword: this.parameters.search,
      keywordField: <string>this.parameters.searchBy.value || '',
      productType: <string>(
        this.parameters.productType
          .map(type =>
            this.productTypeOptions
              .find(option => option.label === type)
              ?.value.toUpperCase()
          )
          .join(',')
      ),
      reasonId: <string>(
        this.parameters.cancelReasonType
          .map(
            type =>
              this.cancelReasonOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      totalNfdStatus: this.parameters.totalNfdStatus?.value || '',
      sortField: this.parameters.sort.name || '',
      sortDirection: this.parameters.sort.direction || '',
    }
  }

  get latestSync(): string {
    if (!this.controller.latestSync) {
      return ''
    }

    return dayjs(this.controller.latestSync).format('DD-MM-YY HH:mm')
  }

  get filterCounterBadge(): number {
    let total =
      this.parameters.cancelReasonType.length +
      this.parameters.productType.length
    return this.parameters.totalNfdStatus?.value ? total + 1 : total
  }

  get summaryData(): PickupCorporateSummary {
    return this.controller.pickupCorporateSummary
  }

  get totalSummaryData(): number {
    return (
      (this.summaryData.one || 0) +
      (this.summaryData.two || 0) +
      (this.summaryData.three || 0) +
      (this.summaryData.four || 0) +
      (this.summaryData.five || 0) +
      (this.summaryData.moreThanFive || 0)
    )
  }

  fetchPickup(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.fetchPickupCorporateList(this.params)
    this.controller.fetchCorporateSummary()
  }

  onFilterReset(): void {
    this.parameters = {
      ...this.parameters,
      cancelReasonType: [],
      productType: [],
      totalNfdStatus: null,
    }

    this.fetchPickup(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    sortName?: string,
    sortWidth?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort: Boolean(sortName),
      sortName: sortName,
      sortStyle: {
        marginLeft: sortWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | string[] | boolean[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  public formatNFDStatus(value: string | number): string {
    const status = parseInt(<string>value)
    if (status === 1) return 'one'
    if (status === 2) return 'two'
    if (status === 3) return 'three'
    if (status === 4) return 'four'
    if (status === 5) return 'five'
    return 'more'
  }

  public getOverSLA(createdAt: string): boolean {
    return dayjs().diff(dayjs(createdAt), 'hours') >= 48
  }

  public isBosspack(productType: string): boolean {
    return productType.includes('BOSSPACK')
  }

  public getDuration(createdAt: string): string {
    const duration = dayjs.duration(dayjs().diff(dayjs(createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  public onSearch = Utils.debounce(() => {
    this.fetchPickup(true)
  }, 400)

  public getPickupData(index: number): PickupCorporate {
    return this.controller.pickupCorporateData[Number(index)]
  }

  public getlastCourier(index: number): string {
    const crr = this.controller.pickupCorporateData[index].couerierPickupDetail
    if (!crr) return '-'
    return `[${crr.courierId}] ${crr.courierName} (${crr.courierTypeValid}) ${crr.phoneNumber} • ${crr.partnerName}`
  }

  @Watch('controller.pickupCorporateData')
  onPickupCorporateDataChanged(data: PickupCorporate[]): void {
    this.tableData = data.map((pickup, index) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px'
        ),
        this.tableCellMapper(<string>pickup.shipmentId, '160px'),
        this.tableCellMapper(
          [
            <string>pickup.pickup?.fullname || '-',
            `(${pickup.clientId})`,
            <string>pickup.clientId,
          ],
          '200px'
        ),
        this.tableCellMapper(
          <string>pickup.pickup?.phoneNumber || '-',
          '190px'
        ),
        this.tableCellMapper(
          [
            this.getOverSLA(<string>pickup.history.createdAt),
            <boolean>pickup.isOnepack,
            this.isBosspack(<string>pickup.productType),
          ],
          '140px'
        ),
        this.tableCellMapper(
          <string[]>[
            Utils.formatDateWithIDLocale(
              <string>pickup.history.createdAt,
              'DD MMM YYYY'
            ),
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(pickup.history.createdAt, 'HH:mm Z')
            ),
          ],
          '176px'
        ),
        this.tableCellMapper(this.getDuration(pickup.history.createdAt), '160px'),
        this.tableCellMapper(pickup.totalNfd, '140px'),
        this.tableCellMapper(
          [
            <string>pickup.shipmentId,
            `${pickup.pickup?.latitude}:${pickup.pickup?.longitude}`,
          ],
          '166px'
        ),
      ]
    })
  }
}
